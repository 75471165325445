import React, { Fragment } from 'react'
import { withRouter, Redirect } from 'react-router-dom'
import { connect } from 'react-redux'
import NProgress from 'nprogress'
import { Helmet } from 'react-helmet'
import { useAuth0 } from "../auth0"
import PublicLayout from './Public'
import LoginLayout from './Login'
import MainLayout from './Main'
import Cookies from 'js-cookie'
import SelectPlan from './SelectPlan'

const Layouts = {
  public: PublicLayout,
  login: LoginLayout,
  main: MainLayout,
  selectPlan: SelectPlan,
}

// import { useAuth0 } from "../../../auth0";

const mapStateToProps = ({ user }) => ({ user })


const AuthProxy = ({ path, defaultView, loggedInUser, setUser }) => {
  const { isAuthenticated, loginWithRedirect, user } = useAuth0();
  const auth = useAuth0()

  if (isAuthenticated) {
    if (user.updated_at !== loggedInUser.updated_at) {
      auth.getIdTokenClaims().then(d => setUser({ ...d }))
    }
  }
  if (!isAuthenticated) {
    window.setTimeout(() => loginWithRedirect(), 500)
    // return <button onClick={() => loginWithRedirect()}>Log In</button>
  }

  return (
    <div>
      {isAuthenticated && !path === "/home" && <Redirect to="/home" />}
      {isAuthenticated && defaultView}
    </div>
  );
};

//@withRouter
//@connect(({ user }) => ({ user }))
class IndexLayout extends React.Component {
  previousPath = ''

  componentDidUpdate(prevProps) {
    const { location } = this.props
    const { prevLocation } = prevProps
    if (location !== prevLocation) {
      window.scrollTo(0, 0)
    }
  }

  dispatchLogin = (user) => {
    const { dispatch } = this.props
    Cookies.set('user', user.__raw)
    dispatch({
      type: 'user/LOGIN',
      payload: user,
    })
  }

  render() {
    const {
      children,
      location: { pathname, search },
      user,
    } = this.props

    // NProgress Management
    const currentPath = pathname + search

    if (currentPath !== this.previousPath) {
      NProgress.start()
    }

    setTimeout(() => {
      NProgress.done()
      this.previousPath = currentPath
    }, 300)

    // Layout Rendering
    const getLayout = () => {
      if (pathname === '/') {
        return 'public'
      }
      if (pathname === '/register' || pathname==="/termsandconditions") {
        return 'login'
      }

      if(pathname === '/subscription/select-plan'){
        return 'selectPlan'
      }

      return 'main'
    }

    const Container = Layouts[getLayout()]

    return (
      <Fragment>
        <Helmet titleTemplate="Surfside | %s" title="Audience Intelligence" />
        <AuthProxy
          path={currentPath}
          defaultView={<Container>{children}</Container>}
          loggedInUser={user}
          setUser={this.dispatchLogin}
        />
      </Fragment>
    )
  }
}

//export default IndexLayout

export default withRouter(connect(mapStateToProps)(IndexLayout))
/*
 const isUserAuthorized = user.authorized
    const isUserLoading = user.loading
    const isLoginLayout = getLayout() === 'login'
    */