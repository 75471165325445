import { ConnectedService } from "services/connected-service";
import { IPlacementService } from "./iplacement-service";
import { Placement, PlacementsAttributes, UpdatePlacement } from "models/dsp/placements.dto";
import { ConnectedServiceResult } from "services/connected-service-result";
import moment, { Moment } from "moment";
import { APIQuery } from "models/query/query.dto";

type ConnectedPlacement = ConnectedServiceResult<Placement>;
type ConnectedPlacements = ConnectedServiceResult<Placement[]>;

export class ConnectedPlacementService extends ConnectedService implements IPlacementService {
  async createPlacement(placement: Placement): Promise<Placement> {
    let newPlacement = await this._post<ConnectedPlacement>('dsp/placements', placement);
    if (newPlacement.success) {
      return newPlacement.data!;
    } else {
      throw new Error(newPlacement.message);
    }
  }

  async updatePlacement(placement: Placement): Promise<Placement> {
    let updated = await this._put<ConnectedPlacement>('dsp/placements', placement);
    if (updated.success) {
      return updated.data!;
    } else {
      throw new Error(updated.message);
    }
  }

  async getPlacements(query: string | undefined, page: number, limit: number, lineItemId?: string): Promise<Placement[]> {
    try {
      let args = {
        ...(query ? {query} : {}),
        page,
        limit,
        ...(lineItemId ? {lineItemId} : {})
      };

      let searchParams = Object.entries(args).map(entry => {
        return `${entry[0]}=${entry[1]}`;
      }).join("&");

      let placement = await this._get<ConnectedPlacements>(`dsp/placements?${searchParams}`);
      if (placement.success) {
        if (placement.data) {
          return placement.data ?? [];
        }
      }
    } catch (e) {
      return [];
    }
    return [];
  }


  async getPlacementById(id: string): Promise<Placement | null> {
    try {
      let placement = await this._get<ConnectedPlacement>(`dsp/placements/${id}`);
      if (placement.success) {
        if (placement.data) {
          placement.data.interval = placement.data?.interval.map(d => moment(new Date(d as unknown as string))) as [Moment, Moment];
          if(!placement.data.ruleGroups || placement.data.ruleGroups.values.length===0){
            placement.data.ruleGroups={operator: 'OR', values: [[]]}
          }
        }

        return placement.data!;
      }
    } catch (e) {
      return null;
    }
    return null;
  }

  async queryPlacements(query: APIQuery): Promise<Placement[]> {
    const response = await this._post<ConnectedPlacements>('dsp/placements/query', query)
    if (response.success === true && response.data !== undefined) {
      return response.data
    }
    throw new Error(response.message)
  }


}