/* eslint-disable no-underscore-dangle */
import React from 'react'

import { Provider } from 'react-redux'

// import { logger } from 'redux-logger'
import thunk from 'redux-thunk'
import { routerMiddleware } from 'connected-react-router'
import { createStore, applyMiddleware, compose } from 'redux'
import createSagaMiddleware from 'redux-saga'
import { createBrowserHistory } from 'history'
import reducers from 'redux/reducers'
import sagas from 'redux/sagas'
import Router from 'router'
import Localization from 'components/LayoutComponents/Localization'
import Loader from 'components/LayoutComponents/Loader'
import { useAuth0 } from './auth0'

import './global.scss'
import ServiceContextProvider from 'service-context-provider'

import { MockAccountService } from 'services/iam/account/mock-account-service'
import { MockAdvertiserService } from 'services/dsp/advertiser/mock-advertiser-service'
import { MockCampaignService } from 'services/dsp/campaign/mock-campaign-service'
import { MockSourceService } from 'services/dmp/source/mock-source-service'
import { MockStrategyService } from 'services/dsp/strategy/mock-strategy-service'
import { MockTagService } from 'services/dsp/tag/mock-tag-service'
import { MockRuleService } from 'services/dsp/rule/mock-rule-service'
import { MockBannerService } from 'services/dsp/banner/mock-banner-service'
import { MockUploadService } from 'services/dsp/upload/mock-upload-service'
import { MockVideoService } from 'services/dsp/video/mock-video-service'
import { MockPlacementService } from 'services/dsp/placement/mock-placement-service'
import { MockTargetService } from 'services/dsp/target/mock-target-service'
import { MockNativeService } from 'services/dsp/native/mock-native-service'
import { MockListService } from 'services/dsp/list/mock-list-service'
import { MockAnalyticService } from 'services/dsp/analytic/mock-analytic-service'
import { MockUserMetaService } from 'services/meta/mock-user-meta-service'


import { ConnectedAccountService } from 'services/iam/account/connected-account-service'
import { ConnectedAdvertiserService } from 'services/dsp/advertiser/connected-advertiser-service'
import { ConnectedCampaignService } from 'services/dsp/campaign/connected-campaign-service'
import { ConnectedSourceService } from 'services/dmp/source/connected-source-service'
import { ConnectedStrategyService } from 'services/dsp/strategy/connected-strategy-service'
import { ConnectedCreativeService } from 'services/dsp/creatives/connected-creative-service'
import { MockCreativeService } from 'services/dsp/creatives/mock-creative-service'
import { ConnectedTagService } from 'services/dsp/tag/connected-tag-service'
import { ConnectedRuleService } from 'services/dsp/rule/connected-rule-service'
import { ConnectedBannerService } from 'services/dsp/banner/connected-banner-service'
import { ConnectedUploadService } from 'services/dsp/upload/connected-upload-service'
import { ConnectedVideoService } from 'services/dsp/video/connected-video-service'
import { ConnectedPlacementService } from 'services/dsp/placement/connected-placement-service'
import { ConnectedTargetService } from 'services/dsp/target/connected-target-service'
import { ConnectedNativeService } from 'services/dsp/native/connected-native-service'
import { ConnectedBulkEditService } from 'services/dsp/bulk-edit/bulk-edit-service'
import { ConnectedListService } from 'services/dsp/list/connected-list-service'
import { ConnectedAnalyticService } from 'services/dsp/analytic/connected-analytic-service'
import { ConnectedUserMetaService } from 'services/meta/user-meta-service'

const history = createBrowserHistory() //createHashHistory()
const sagaMiddleware = createSagaMiddleware()
const routeMiddleware = routerMiddleware(history)
const middlewares = [thunk, sagaMiddleware, routeMiddleware]

if (process?.env?.NODE_ENV === 'development' && true) {
  // middlewares.push(logger)
}
const store = createStore(reducers(history), compose(applyMiddleware(...middlewares)))

// if (process.env.NODE_ENV === 'development' && true) {
//   // middlewares.push(logger)
// }
// const store = createStore(reducers(history),
// compose(applyMiddleware(...middlewares)),
// )

if (process?.env?.REACT_APP_SURFSIDE_ENV === 'production') {
  // console.log = () => { }
  // console.error = () => { }
}

if (process) sagaMiddleware.run(sagas, store.dispatch)

function App() {
  const auth0User = useAuth0()

  const { loading } = auth0User

  if (loading) {
    return <Loader />
  }

  let accountService
  let advertiserService
  let campaignService
  let sourceService
  let strategyService
  let tagService
  let ruleService
  let bannerService
  let uploadService
  let videoService
  let creativeService
  let placementService
  let targetingService
  let nativeService
  let listService
  let bulkEditService
  let analyticService
  let userMetaService

  const production = process.env.REACT_APP_SURFSIDE_SERVICES_ENDPOINT ?? 'http://localhost:3100/api';
  const localhost = "http://localhost:3100/api"

  const mock = process.env.REACT_APP_USE_MOCKS;
  const env = process.env.NODE_ENV

  if (mock === "true") {
    console.log('Using Mock Services')
    accountService = new MockAccountService()
    advertiserService = new MockAdvertiserService()
    campaignService = new MockCampaignService()
    sourceService = new MockSourceService()
    strategyService = new MockStrategyService()
    tagService = new MockTagService()
    ruleService = new MockRuleService()
    bannerService = new MockBannerService()
    uploadService = new MockUploadService()
    videoService = new MockVideoService()
    creativeService = new MockCreativeService()
    placementService = new MockPlacementService()
    targetingService = new MockTargetService()
    nativeService = new MockNativeService()
    listService = new MockListService()
    analyticService = new MockAnalyticService()
    userMetaService = new MockUserMetaService()
  } else if (env === 'development') {
    console.log('Using localhost Services')
    accountService = new ConnectedAccountService(localhost)
    advertiserService = new ConnectedAdvertiserService(localhost)
    campaignService = new ConnectedCampaignService(localhost)
    sourceService = new ConnectedSourceService(localhost)
    strategyService = new ConnectedStrategyService(localhost)
    tagService = new ConnectedTagService(localhost)
    ruleService = new ConnectedRuleService(localhost)
    bannerService = new ConnectedBannerService(localhost)
    uploadService = new ConnectedUploadService(localhost)
    videoService = new ConnectedVideoService(localhost)
    creativeService = new ConnectedCreativeService(localhost)
    placementService = new ConnectedPlacementService(localhost)
    targetingService = new ConnectedTargetService(localhost)
    nativeService = new ConnectedNativeService(localhost)
    listService = new ConnectedListService(localhost)
    bulkEditService = new ConnectedBulkEditService(localhost)
    analyticService= new ConnectedAnalyticService(localhost)
    userMetaService = new ConnectedUserMetaService(localhost)
  } else {
    console.log('Using Production Services')
    accountService = new ConnectedAccountService(production)
    advertiserService = new ConnectedAdvertiserService(production)
    campaignService = new ConnectedCampaignService(production)
    sourceService = new ConnectedSourceService(production)
    strategyService = new ConnectedStrategyService(production)
    tagService = new ConnectedTagService(production)
    ruleService = new ConnectedRuleService(production)
    bannerService = new ConnectedBannerService(production)
    uploadService = new ConnectedUploadService(production)
    videoService = new ConnectedVideoService(production)
    creativeService = new ConnectedCreativeService(production)
    placementService = new ConnectedPlacementService(production)
    targetingService = new ConnectedTargetService(production)
    nativeService = new ConnectedNativeService(production)
    listService = new ConnectedListService(production)
    bulkEditService = new ConnectedBulkEditService(production)
    analyticService= new ConnectedAnalyticService(production)
    userMetaService = new ConnectedUserMetaService(production)
  }

  return (
    <ServiceContextProvider
      accountService={accountService}
      advertiserService={advertiserService}
      campaignService={campaignService}
      sourceService={sourceService}
      strategyService={strategyService}
      creativeService={creativeService}
      tagService={tagService}
      ruleService={ruleService}
      bannerService={bannerService}
      uploadService={uploadService}
      videoService={videoService}
      placementService={placementService}
      targetingService={targetingService}
      nativeService={nativeService}
      listService={listService}
      bulkEditService={bulkEditService}
      analyticService={analyticService}
      userMetaService={userMetaService}
    >
      <Provider store={store}>
        <Localization>
          <Router history={history} />
        </Localization>
      </Provider>
    </ServiceContextProvider>
  )
}

export { store, history, App }
