import { createContext } from "react";
import { IAdvertiserService } from "services/dsp/advertiser/iadvertiser-service";
import { ICampaignService } from "services/dsp/campaign/icampaign-service";
import { IAccountService } from "services/iam/account/iaccount-service";
import { ISourceService } from "services/dmp/source/isource-service";
import { IStrategyService } from "services/dsp/strategy/istrategy-service";
import { ICreativeService } from "services/dsp/creatives/icreative-service";
import { ITagService } from "services/dsp/tag/itag-service";
import { IRuleService } from "services/dsp/rule/irule-service";
import { IBannerService } from 'services/dsp/banner/ibanner-service'
import { IUploadService } from "services/dsp/upload/iupload-service";
import { IPlacementService } from "services/dsp/placement/iplacement-service";
import { IVideoService } from 'services/dsp/video/ivideo-service';
import { ITargetService } from "services/dsp/target/itarget-service";
import { INativeService } from "services/dsp/native/inative-service";
import { IListService } from "services/dsp/list/ilist-service";
import { IBulkEditService } from "services/dsp/bulk-edit/ibulk-edit-service";
import { IAnalyticService } from "services/dsp/analytic/ianalytic-service";
import { IUserMetaService } from "services/meta/iuser-meta-service";

export interface ServiceContextServices {
  accountService: IAccountService,
  advertiserService: IAdvertiserService,
  campaignService: ICampaignService,
  sourceService: ISourceService,
  strategyService: IStrategyService,
  creativeService: ICreativeService,
  tagService: ITagService,
  ruleService: IRuleService,
  uploadService: IUploadService,
  bannerService: IBannerService,
  placementService: IPlacementService,
  videoService: IVideoService,
  targetingService: ITargetService
  nativeService: INativeService,
  listService: IListService,
  bulkEditService:IBulkEditService,
  analyticService:IAnalyticService,
  userMetaService: IUserMetaService
}

export const ServiceContext = createContext<ServiceContextServices | undefined>(undefined);